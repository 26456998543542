/* HOMEPAGE CSS */

body{
    margin: 0;
    height: 100%;
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#body-container {
    background-size: cover;
    background-position: center;
    transition: opacity 0.2s;
    -webkit-transition: opacity 0.2s; /* Safari */
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 0 100vw rgba(0,0,0,0.6);
    background-image: url('../../../public/images/contract.jpg');
    display: flex;
    justify-content: center;
    flex-direction: column;
}
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.landing_page_section {
    position: relative;
    padding-top: 30px;
}

.landing_page_section .landing_page_heading {
    color: #ffffff;
    margin-bottom: 40px;
}

.landing_page_section .btn {
    padding: 20px 20px;
}

/* start animaçao */
.landing_page_section .animated_fadein_down,
.header .animated_fadein_down {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 2s;
    -moz-animation-duration: 2s;
    -ms-animation-duration: 2s;
    -o-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

@keyframes fade_n {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,40px,0)
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}
@-moz-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,40px,0)
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}
@-o-keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,40px,0)
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0,40px,0)
    }
    100% {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
    }
}

@keyframes fadeInDown {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.landing_page_section .animated_fade,
.body .animated_fade {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    -ms-animation-duration: 1.5s;
    -o-animation-duration: 1.5s;
    animation-duration: 1.5s;
    animation-delay: 0.5s;
    -webkit-animation-name: fade_n;
    -moz-animation-name: fade_n;
    -o-animation-name: fade_n;
    animation-name: fade_n;
}

.landing_page_section .animated_fadein_dup {
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    -ms-animation-fill-mode: both;
    -o-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -moz-animation-duration: 1s;
    -ms-animation-duration: 1s;
    -o-animation-duration: 1s;
    animation-duration: 1s;
    animation-delay: 0.7s;
    -webkit-animation-name: fadeInUp;
    -moz-animation-name: fadeInUp;
    -o-animation-name: fadeInUp;
    animation-name: fadeInUp;
}


.btn_landing_page{
    font-size: 115%;
    background-color: white;
    color: black;
    border-radius: 40px;
}

.btn_landing_page:hover {
    font-size: 115%;
    background-color: gray;
    color: white;
    border-radius: 40px;
}


.landing_page_section .landing_page_text_intro{
    color: white;
    font-size: 125%;
    text-align: justify;
}

/* MEDIA QUERYS */
@media  (min-width: 1250px) {
    .landing_page_heading {
        font-size: 337.5%;
    }
    .success_msg{
        font-size: 46.25%;
    }
    p.error_page_description {
        font-size: 43%;
        color: white;
    }
    p.error_number{
        font-size: 150%;
    }
    .error_page_go_home{
        font-size: 56%!important;
    }
}

@media (max-width: 1249px) and (min-width: 421px) {
    p.error_page_description {
        font-size: 119%;
        color: white;
    }
    p.error_number{
        font-size: 505%;
    }
}

@media (max-width: 420px) {
    .landing_page_heading {
        font-size: 218.75%;
    }
    .landing_page_section .landing_page_text_intro{
        margin: 0px -12px 0px -12px;
    }
    .success_msg{
        font-size: 41.75%;
    }
    p.error_page_description {
        font-size: 54%;
        color: white;
    }
    p.error_number{
        font-size: 290%;
    }
    .error_page_go_home{
        font-size: 56%!important;
    }
}

.language_switcher_landingpage{
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: underline;
    font-size: 75%;
    text-align: right;
    margin-right: 16px;
    margin-top: 8px;
    list-style-type: none;
}

.language_switcher_landingpage a{
    color: #fff !important
}

section.landing_page_section h1>a {
    color: white;
    text-decoration: none;
}

p.landing_page_text_intro a , strong > a, div.success_msg a {
    color: white;
}

strong > a:hover {
    color: white;
    text-decoration: underline;
}

div.success_msg h1{
    font-size: 119.25%;
}

.landing_page_section .btn_homepage_user_support {
    font-size: 95%;
    background-color: #17a2b8;
    color: white;
}

.landing_page_section .btn_homepage_user_support:hover {
    font-size: 95%;
    background-color: white;
    color: #17a2b8;
}